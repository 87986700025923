import React from "react"
import styled from "@emotion/styled"
import val1 from "../images/val1.png"
import val2 from "../images/val2.jpg"
import val3 from "../images/val3.jpg"

const RestaurantFullPage = () => {
  return (
    <SectionContainer>
      <Text>
        <p className="title">LE RESTAURANT</p>
        {/* <p className="subtitle">WORDING 10 LIGNES ---- TITRE</p> */}
        <p className="text text1">
          Hara-kiri, c’est l’histoire de deux frères, que tout oppose en
          apparence mais qui partagent la passion du ramen. C’est aussi
          l’histoire d’une rencontre, celle d’un fan de culture japonaise et
          d’un entrepreneur audacieux.
        </p>
        <p className="text text1">
          Pendant plusieurs mois, nous avons parcouru ensemble le Japon et avons
          dégusté une multitude de recettes, propres à chaque région et
          caractéristiques de l’univers de chaque chef. Imprégnés de cette
          diversité culturelle, nous avons découvert tous les secrets de l’art
          du ramen.
        </p>
        <p className="text">
          Une fois rentrés en France, nous avons ouvert la cantine Hara-Kiri, un
          lieu d’expression à notre image, autour de 3 valeurs qui nous sont
          chères :
        </p>
      </Text>
      <ValuesContainer>
        <Third>
          <ValueImageContainer>
            <img
              className="ValueImage"
              src={val1}
              alt={"cuisinier japonais de ramen"}
            ></img>
          </ValueImageContainer>
          <ValueTitle>LE SAVOIR-FAIRE</ValueTitle>
          <ValueText>
            Chez Hara-Kiri nous revendiquons un savoir-faire enseigné par des
            chefs Tokyoïtes, dans le plus grand respect de la tradition
            japonaise. Tous nos plats sont 100% faits maison et chaque recette a
            été conçue à partir de produits frais, sélectionnés pour la plus
            grande harmonie des saveurs.
          </ValueText>
          <FreeText>
            Hara-Kiri a été conçu dans le but de vous faire vivre une expérience
            unique, vous faire déguster des plats typiques de la culture
            japonaise, accompagnés de Saké « grands crus », dans une ambiance
            chaleureuse et décontractée.
          </FreeText>
        </Third>
        <Third>
          <ValueImageContainer>
            <ValueImageContainer>
              <img
                className="ValueImage"
                src={val2}
                alt={"ramen à la truffe"}
              ></img>
            </ValueImageContainer>
          </ValueImageContainer>
          <ValueTitle>LA CRÉATIVITÉ</ValueTitle>
          <ValueText>
            Une fois imprégnés des recettes traditionnelles japonaises, nous
            avons voulu apporter notre touche française et proposer une version
            revisitée : le ramen à la truffe noire. En alliant créativité et
            audace, nous sommes parvenu à trouver l’équilibre parfait entre les
            saveurs asiatiques et l’originalité de la truffe noire. Nous en
            avons fait notre signature.
          </ValueText>
        </Third>
        <Third>
          <ValueImageContainer>
            <ValueImageContainer>
              <img
                className="ValueImage"
                src={val3}
                alt={"viande de porc tasty"}
              ></img>
            </ValueImageContainer>
          </ValueImageContainer>
          <ValueTitle>L'EXPÉRIENCE</ValueTitle>
          <ValueText>
            Dans cette cantine nippone, chaque détail a été réfléchi pour vous
            faire vivre une expérience unique, de la décoration épurée du lieu
            jusqu’au dressage minutieux de nos plats. Vous pourrez vous
            installer à table ou au comptoir avec une vue directe sur nos nos
            marmites de bouillon frémissant, vous laisser enivrer par les odeurs
            et emporter par la musique rock indé. Pour accompagner les plats,
            nous proposons un large choix de saké, tout droit importés du Japon.
            Qu’il soit sec, fruité ou pétillant, nous le servons dans des masu
            (récipients cubiques en bois), en accord avec la tradition
            japonaise. En optant pour notre formule découverte, vous pourrez
            comparer trois sortes de saké et comprendre alors les différentes
            subtilités.
          </ValueText>
        </Third>
      </ValuesContainer>
    </SectionContainer>
  )
}

const SectionContainer = styled.div`
  margin-top: 80px;
`

const ValuesContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 80px;
  width: 100%;
  min-height: 60vh;
  display: flex;
  justify-content: space-evenly;
  position: relative;
  @media screen and (max-width: 640px) {
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 80px;
  }
`

const Third = styled.div`
  width: 30%;
  max-width: 300px;
  height: 100%;
  position: relative;

  @media screen and (max-width: 640px) {
    width: 100%;
    max-width: none;
  }
`

const ValueImageContainer = styled.div`
  width: 100%;
  height: 220px;
  background-color: #fff;

  .ValueImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 640px) {
    height: 300px;
  }
`

const ValueTitle = styled.p`
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  margin: 30px 0;
`

const ValueText = styled.p`
  font-size: 15px;
  text-align: justify;

  @media screen and (max-width: 640px) {
    padding: 0 40px;
    margin-bottom: 40px;
  }
`

const FreeText = styled.p`
  position: absolute;
  bottom: -198px;
  left: 5%;
  max-width: 600px;
  text-align: center;
  width: 50vw;
  font-weight: 600;

  @media screen and (max-width: 640px) {
    /* bottom: -1420px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%; */
    display: none;
  }
`

const Text = styled.div`
  width: 80%;
  max-width: 960px;
  margin: 0 auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media screen and (max-width: 640px) {
    width: 100%;

    padding: 10%;
    height: auto;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
  }

  .text {
    line-height: 1.6;
    font-size: 15px;
  }

  .text1 {
    margin-bottom: 20px;
  }
`

export default RestaurantFullPage
