import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import RestaurantFullPage from "../components/RestaurantFullPage"

const RestaurantPage = () => (
  <Layout bgImage>
    <SEO
      title="Le restaurant hara kiri ramen"
      description="Découvrez l'histoire et les valeurs de notre restaurant de ramen"
    />
    <RestaurantFullPage />
  </Layout>
)

export default RestaurantPage
